<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        اضافة جديد
      </template>
      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/users/clients')"
        />
      </template>
    </Toolbar>
    <div class="form-card row">
      <div class="col-md-6">
        <div class="mb-3">
          <label for="userName" class="form-label required">
            اسم العميل
            <span>*</span>
          </label>
          <input
            type="text"
            class="form-control"
            id="userName"
            v-model="body.name"
            :class="{ 'is-invalid': isInvalid && !body.name }"
            required
            placeholder="برجاء التأكد من عدم وجود اسم العميل قبل الاضافة"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3 text-right ">
          <label for="firstSupport" class="form-label">
            الاولوية
          </label>

          <div class="p-field-checkbox firstSupport">
            <Checkbox
              id="firstSupport"
              name="firstSupport"
              :value="true"
              v-model="body.firstSupport"
            />
            <label for="firstSupport">
              له أولوية في صيانة التركيبات
            </label>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="countriesId" class="form-label required">
            الدوله
            <span>*</span>
          </label>

          <select
            class="form-select form-control"
            id="countriesId"
            name="countriesId"
            v-model.number="body.countriesId"
            :class="{ 'is-invalid': isInvalid && !body.countriesId }"
            required
          >
            <option
              v-for="item of countriesList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="citiesId" class="form-label required">
            المدينه
            <span>*</span>
          </label>

          <select
            class="form-select form-control"
            id="citiesId"
            name="citiesId"
            v-model.number="body.citiesId"
            :class="{ 'is-invalid': isInvalid && !body.citiesId }"
            required
          >
            <option
              v-for="item of citiesList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select>
        </div>
      </div>

      <div class="col-md-12">
        <div class="mb-3">
          <label for="address" class="form-label required">
            العنوان
            <span>*</span>
          </label>
          <input
            type="text"
            class="form-control"
            id="address"
            v-model="body.address"
            :class="{ 'is-invalid': isInvalid && !body.address }"
            required
          />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="mailBox" class="form-label">
            ص . ب
          </label>
          <input
            type="text"
            class="form-control"
            id="mailBox"
            v-model="body.mailBox"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="zipCode" class="form-label">
            رمز البريد
          </label>
          <input
            type="text"
            class="form-control"
            id="zipCode"
            v-model="body.zipCode"
          />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="phone" class="form-label required">
            الهاتف
            <span>*</span>
          </label>
          <input
            type="tel"
            class="form-control"
            id="phone"
            v-model="body.phone"
            :class="{ 'is-invalid': isInvalid && !body.phone }"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="fax" class="form-label">
            الفاكس
          </label>
          <input type="tel" class="form-control" id="fax" v-model="body.fax" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="managerName" class="form-label required">
            اسم المسئول
            <span>*</span>
          </label>
          <input
            class="form-control"
            id="managerName"
            v-model="body.managerName"
            :class="{ 'is-invalid': isInvalid && !body.managerName }"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="managerPhone" class="form-label required">
            جول المسئول
            <span>*</span>
          </label>
          <input
            type="tel"
            class="form-control"
            id="managerPhone"
            v-model="body.managerPhone"
            :class="{ 'is-invalid': isInvalid && !body.managerPhone }"
            required
          />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="userName" class="form-label required">
            اسم المستخدم
            <span>*</span>
          </label>
          <input
            type="text"
            class="form-control"
            id="userName"
            v-model="body.userName"
            :class="{ 'is-invalid': isInvalid && !body.userName }"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="clientGroupsId" class="form-label required">
            مجموعه العملاء
            <span>*</span>
          </label>

          <select
            class="form-select form-control"
            id="clientGroupsId"
            name="clientGroupsId"
            v-model.number="body.clientGroupsId"
            :class="{ 'is-invalid': isInvalid && !body.clientGroupsId }"
            required
          >
            <option
              v-for="item of clientGroupsList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select>
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="email" class="form-label required">
            البريد الالكتروني
            <!-- <span>*</span> -->
          </label>
          <input
            type="email"
            class="form-control"
            id="email"
            v-model="body.email"
            :class="{ 'is-invalid': isInvalid && !body.email }"
            required
          />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="password" class="form-label required">
            كلمه المرور
            <span>*</span>
          </label>
          <input
            type="password"
            class="form-control"
            id="password"
            v-model="body.password"
            :class="{ 'is-invalid': isInvalid && !body.password }"
            required
          />
        </div>
      </div>

      <div class="col-md-12">
        <div class="mb-3">
          <label for="note" class="form-label">
            ملاحظات
          </label>
          <b-form-textarea
            id="textarea-small"
            name="note"
            v-model="body.note"
            placeholder=""
          ></b-form-textarea>
        </div>

        <div class="mb-3 text-center">
          <Button
            label="تعديل"
            icon="pi pi-pencil"
            v-tooltip="'تعديل'"
            class="p-ml-2 p-button-info"
            :disabled="disabledAdd"
            @click="update()"
            v-if="id"
          />
          <Button
            v-else
            label="اضافة جديد"
            icon="pi pi-plus"
            v-tooltip="'اضافه جديد'"
            class="p-ml-2 p-button-success"
            @click="save()"
            :disabled="disabledAdd"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const user = JSON.parse(localStorage.admin);

    return {
      body: {
        userName: null,
        usersId: user.id,
        email: null,
        password: null,
        userNotes: null,
        name: null,
        firstSupport: null,
        countriesId: null,
        citiesId: null,
        address: null,
        mailBox: null,
        zipCode: null,
        phone: null,
        fax: null,
        managerName: null,
        managerPhone: null,
        clientGroupsId: null,
      },
      id: null,
      countriesList: [],
      citiesList: [],
      clientGroupsList: [],

      isInvalid: false,
      disabledAdd: false,
    };
  },
  methods: {
    save() {
      if (
        this.body.name &&
        this.body.userName &&
        // this.body.email &&
        this.body.password &&
        this.body.clientGroupsId &&
        this.body.managerName &&
        this.body.managerPhone &&
        this.body.countriesId &&
        this.body.citiesId &&
        this.body.address
      ) {
        this.disabledAdd = true;
        this.$http.post(`clients`, this.body).then(
          () => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم الاضافة بنجاح',
              life: 3000,
            });
            this.$router.push('/admin/users/clients');
          },
          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجي اضافة الحقول المطلوبه',
          life: 3000,
        });
      }
    },
    update() {
      if (
        this.body.name &&
        this.body.userName &&
        // this.body.email &&
        // this.body.password &&
        this.body.clientGroupsId &&
        this.body.managerName &&
        this.body.managerPhone &&
        this.body.countriesId &&
        this.body.citiesId &&
        this.body.address
      ) {
        this.disabledAdd = true;

        this.$http.put(`clients/${this.id}`, this.body).then(
          () => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
            this.$router.push('/admin/users/clients');
          },
          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجي اضافة الحقول المطلوبه',
          life: 3000,
        });
      }
    },
  },
  created() {
    if (!this.$checkRoles('clientsAdd')) {
      this.$router.push('/admin/');
    }
    this.$http.get(`countries`).then((res) => {
      this.countriesList = res.data;
    });

    this.$http.get(`clientGroups`).then((res) => {
      this.clientGroupsList = res.data;
    });

    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.$http.get(`clients/${this.id}`).then(
        (res) => {
          this.body = res.data;
          this.body.password = null;
          this.body.countriesId = res.data.countriesId?.id;
          this.body.citiesId = res.data.citiesId?.id;
          this.body.usersId = res.data.usersId?.id;
          this.body.clientGroupsId = res.data.clientGroupsId?.id;
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    } else {
      this.$http.get(`company`).then(
        (res) => {
          this.body.countriesId = res.data.countriesId?.id;
          this.body.citiesId = res.data.citiesId?.id;
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    }
  },
  watch: {
    'body.countriesId'(val) {
      this.$http.get(`cities?countriesId=${val}`).then((res) => {
        this.citiesList = res.data;
      });
    },
  },
};
</script>

<style>
.firstSupport label {
  display: inline;
  text-align: start !important;
  margin-right: 11px;
}
</style>
